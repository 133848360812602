import '../styles/globals.css'
import { FC, Fragment } from 'react'
import type { AppProps } from 'next/app'
import SEO from '../next-seo.config'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import TrackingScripts from '@components/tracking-scripts'
import { AuthProvider } from '@lib/auth/provider'
import { useOpenReplayTracker } from '@hooks/use-open-replay'
import { Manrope, Inter } from '@next/font/google'
import { GeistProvider } from '@geist-ui/core'
import Script from 'next/script'
import { isDevEnv } from '@utils/is-dev-env'
import { ThemeProvider } from 'next-themes'

export function PaddleLoader() {
  return (
    <Script
      src="https://cdn.paddle.com/paddle/paddle.js"
      onLoad={() => {
        if (isDevEnv()) {
          // @ts-ignore
          Paddle.Environment.set('sandbox')
        }

        // @ts-ignore
        Paddle.Setup({
          vendor: isDevEnv() ? 6841 : 150254,
          eventCallback: function (data) {
            console.log({ data })
            if (data.event === 'Checkout.Complete') {
              // Check to ensure the payment has not been flagged for manual fraud review
              if (!data.eventData.flagged) {
                var checkoutId = data.eventData.checkout.id

                console.log('do this here....')

                // @ts-ignore
                Paddle.Order.details(checkoutId, function (data) {
                  if (!!data) {
                    // Order data, downloads, receipts etc... available within 'data' variable
                    console.log(data)
                  } else {
                    // Order processing delay - order details cannot be retrieved at the moment
                    console.log('Order is being processed')
                  }
                })
              } else {
                // Payment has not been fully processed at the moment, so order details cannot be retrieved
                console.log('Transaction pending review')
              }
            }
          },
        })
      }}
    />
  )
}

// const manrope = Manrope({
//   weight: 'variable',
//   subsets: ['latin'],
//   display: 'swap',
// })

const inter = Inter({ subsets: ['latin'] })

const DynamicNextNProgress = dynamic(() => import('nextjs-progressbar'), {})
const DynamicDefaultSEO = dynamic(() =>
  import('next-seo').then((mod) => mod.DefaultSeo),
) as any
const DynamicQueryClientProvider = dynamic(
  () => import('@lib/query-client-provider'),
) as any

const PROGRESSBAR_OPTIONS = {
  color: '#b11fa8',
  startPosition: 0.3,
  stopDelayMs: 200,
  height: 3,
  showOnShallow: true,
}

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()

  const isSurveyPage = router.pathname === '/[id]'

  useOpenReplayTracker({ shouldRecord: !isSurveyPage })

  if (isSurveyPage) {
    return (
      <>
        <ThemeProvider defaultTheme="light" forcedTheme="light">
          <TrackingScripts />
          <DynamicDefaultSEO {...SEO} />
          <div className={inter.className}>
            <div className={inter.className}>
              <Component {...pageProps} />
            </div>
          </div>
          <style jsx global>
            {`
              :root {
                --dm: ${inter.style.fontFamily};
                --manrope: ${inter.style.fontFamily};
              }
            `}
          </style>
        </ThemeProvider>
      </>
    )
  }

  return (
    <>
      <TrackingScripts />

      <PaddleLoader />

      <DynamicNextNProgress {...PROGRESSBAR_OPTIONS} />
      <DynamicDefaultSEO {...SEO} />
      <ThemeProvider defaultTheme="light" forcedTheme="light">
        <DynamicQueryClientProvider>
          <AuthProvider>
            <GeistProvider>
              <div className={inter.className}>
                <div className={inter.className}>
                  <Component {...pageProps} />
                </div>
              </div>
              <style jsx global>
                {`
                  :root {
                    --dm: ${inter.style.fontFamily};
                    --manrope: ${inter.style.fontFamily};
                  }
                `}
              </style>
            </GeistProvider>
          </AuthProvider>
        </DynamicQueryClientProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
