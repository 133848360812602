import { isWindowAvailable } from '@utils/is-window-available'
import { useEffect } from 'react'

export const useOpenReplayTracker = ({ shouldRecord = true }) => {
  useEffect(() => {
    ;(async function () {
      if (isWindowAvailable() && shouldRecord) {
        const Tracker = (await import('@openreplay/tracker/cjs')).default
        const tracker = new Tracker({
          projectKey: process.env.NEXT_PUBLIC_OPEN_REPLAY_PROJECT_KEY,
        })
        tracker.start()
      }
    })()
  }, [shouldRecord])
}
